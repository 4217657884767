

html {
  scroll-behavior: smooth;
  font-family: Lato;
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #FD3356;
}

::selection {
  color: black;
  background: #FD3356;
}

.App {
  text-align: center;
  cursor: none;
}

body{
  background-color: black;
}

.noise {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url('../src/assets/images/noise.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation .2s infinite;
  opacity: 0.9;
  pointer-events:none;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0)
  }

  10% {
    transform: translate(-5%, -5%)
  }

  20% {
    transform: translate(-10%, 5%)
  }

  30% {
    transform: translate(5%, -10%)
  }

  40% {
    transform: translate(-5%, 15%)
  }

  50% {
    transform: translate(-10%, 5%)
  }

  60% {
    transform: translate(15%, 0)
  }

  70% {
    transform: translate(0, 10%)
  }

  80% {
    transform: translate(-15%, 0)
  }

  90% {
    transform: translate(10%, 5%)
  }

  100% {
    transform: translate(5%, 0)
  }
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  flex-direction: column;
}
